<template>
  <div class="home">
    <div class="wrap">
      <div class="swiper-container bannerHome">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <a :href="`https://www.ccdi.gov.cn/specialn/xtlsdj/xtlsdjtpxw/202402/t20240207_327772.html`" target="_blank"><img class="img_c1" src="../assets/img/banner_djxxjy.png" alt=""></a>
          </div>
          <div class="swiper-slide">
            <a :href="`${apiName}/news`" target="_blank"><img class="img_c1" src="../assets/img/banner_xxxjpsx.png" alt=""></a>
          </div>
          <div class="swiper-slide">
            <a :href="`${apiName}/specail`" target="_blank"><img class="img_c1" src="../assets/img/banner_szsc.png" alt=""></a>
          </div>
          <div class="swiper-slide">
            <a :href="`${apiName}/news`" target="_blank"><img class="img_c1" src="../assets/img/banner_xxesd.png" alt=""></a>
          </div>
          <!-- <div class="swiper-slide">
            <a href="" target="_blank"><img class="img_c1" src="../assets/img/banner_img6.jpg" alt=""></a>
          </div> -->
          <!-- <div class="swiper-slide">
            <img class="img_c1" src="../assets/img/banner_img4.jpg" alt="">
          </div> -->
        </div>
        <div class="swiper-pagination swiper-pagination-banner"></div>
        <div class="swiper-button-prev swiper-button-prev-banner"></div>
        <div class="swiper-button-next swiper-button-next-banner"></div>
      </div>
    </div>

    <div class="homeCd wrap">
      <div class="homeCdItem">
        <a href="https://www.ccdi.gov.cn/specialn/xtlsdj/xtlsdjtpxw/202402/t20240207_327772.html" target="_blank" title="中共中央印发《中国共产党纪律处分条例》">
          <img class="img_c1" src="../assets/img/zt_djxxjy.jpg" alt="">
        </a>
      </div>
    </div>
    
    <div class="mian mainHomeContainer">
      <div class="wrap">
        <!-- 要闻 -->
        <div class="home_pre mainHomeTop clearfloat">
          <div class="home_pre_l fl">
            新闻头条
          </div>
          <!-- <div class="home_pre_r fr">
            <img src="../assets/img/home_top_img01.png" alt="">
          </div> -->
          <div class="inner">
            <el-carousel height="90px" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <div class="mainHomeTopTxt single-line">
                  <a v-if="!item.is_link || item.is_link === 0" class="intro-btn" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">{{ item.title }}</a>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 新闻中心 -->
        <div class="homeNews bgfff">
          <el-row :gutter="30">
            <el-col :span="11">
              <div class="swiper-container bannerHomeSec01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in newsLeft" :key="index">
                    <a target="_blank" :href="!item.is_link || item.is_link === 0 ? `/detail?id=${item.id}` : item.link_url">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-sec01"></div>
              </div>
            </el-col>
            <el-col :span="13">
              <div class="homeNewsBox">
                <div class="homeSecNewHd clearfloat">
                  <div class="fl trans"
                    :class="{'on' : hdActive === index}" 
                    v-for="(item, index) in hdList" 
                    :key="index"
                    @mouseenter="hdShow(index)">
                    <h3>
                      <a :href="`${apiName}/${item.url}`">{{item.name}}</a>
                    </h3>
                  </div>
                </div>
                <!-- <ul class="news_list news_list02" v-if="hdActive === 0">
                  <li v-for="(item,index) in newsRightJtDt" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${gropUrl}/detail?id=${item.id}&navName=新闻中心&groupName=集团动态`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul> -->
                <ul class="news_list news_list02" v-if="(hdActive === 0)">
                  <li v-for="(item,index) in newsRight01" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="(hdActive === 1)">
                  <li v-for="(item,index) in newsRight02" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="(hdActive === 2)">
                  <li v-for="(item,index) in newsRight03" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="homeNewsec02 bgfff mt10">
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="homeNewsec02Hdf">
                <span class="homeNewsec02HdIco"><img src="../assets/img/ico_hd_gg.png" alt=""></span>
                <a :href="`${apiName}/news?t=3`">公示公告</a>
              </div>
              <div class="homeNewsec02Wrap">
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsRightNotice" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="homeNewsec02Hdf">
                <span class="homeNewsec02HdIco"><img src="../assets/img/ico_hd_dsjyxx.png" alt=""></span>
                <span>
                  <a :href="`${apiName}/specail?t=1`">党纪学习教育</a>
                </span>
              </div>
              <div class="homeNewsec02Wrap">
                <ul class="news_list news_list02">
                  <li v-for="(item,index) in newsRightDs" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="homeNewsec03 mt10">
          <div class="swiper-container bannerHomeNewsec03">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in HomeNewsec03List" :key="index">
                <a
                  v-if="!item.is_link || item.is_link === 0"
                  :href="`${apiName}/specail`"
                  :title="item.title">
                  <img :src="item.img" alt="">
                </a>
                <a
                  v-if="item.is_link === 1"
                  target="_blank"
                  :href="item.link_url.indexOf('https://') === 0 || item.link_url.indexOf('http://') === 0 ? item.link_url : `${apiName}/${item.link_url}`"
                  :title="item.title">
                  <img :src="item.img" alt="">
                </a>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination-nessec03"></div>
          </div>
        </div>

        <div class="homeProject bgfff mt10">
          <div class="homeNewsec02Hdf">
            <span class="homeNewsec02HdIco"><img src="../assets/img/ico_hd_xmzs.png" alt=""></span>
            <span>项目展示</span>
          </div>
          <div class="homeProjectW">
            <div class="swiper-container homeProjectSwiper" id="homeProjectSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in homeProjectList" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" href="">
                    <img :src="item.img" alt="">
                    <p class="single-line trans">{{item.title}}</p>
                  </a>
                  <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <img :src="item.img" alt="">
                    <p class="single-line trans">{{item.title}}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="homeZtzl bgfff mt10">
          <div class="homeNewsec02Hdf">
            <span class="homeNewsec02HdIco"><img src="../assets/img/ico_hd_zt.png" alt=""></span>
            <span><a :href="`${apiName}/specail`">专题专栏</a></span>
          </div>
          <div class="homeZtzlWrap">
            <ul class="homeZtzlList clearfloat">
              <li v-for="(item, index) in homeZtzlList" :key="index" class="trans" :class="{active : homeZtzlActive == index}" @mouseenter="mouseenterZtzl(index)">
                <a :href="item.url" target="_blank">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.img"
                    fit="cover"></el-image>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="homeDjgz bgfff mt10">
          <el-row :gutter="30">
            <el-col :span="14">
              <div class="homeDjgzLeft">
                <div class="homeSecNewHd clearfloat">
                  <div class="fl trans"
                    :class="{'on' : hdActive01 === index}" 
                    v-for="(item, index) in hdList01" 
                    :key="index"
                    @mouseenter="hdShow01(index)">
                    <h3>
                      <a :href="`${apiName}/${item.url}`">{{item.name}}</a>
                    </h3>
                  </div>
                </div>
                <ul class="news_list news_list02" v-if="hdActive01 === 0">
                  <li v-for="(item,index) in newsRightDjgz" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="hdActive01 === 1">
                  <li v-for="(item,index) in newsRightJjjc" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="hdActive01 === 2">
                  <li v-for="(item,index) in newsRightQtgz" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
                <ul class="news_list news_list02" v-if="hdActive01 === 3">
                  <li v-for="(item,index) in newsRightGhgz" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="homeDjgzRight">
                <div class="homeNewsec02Hdf">
                  <span class="homeNewsec02HdIco"><img src="../assets/img/ico_hd_zcfg.png" alt=""></span>
                  <span>
                    政策法规
                    <!-- <a :href="`${apiName}/specail?t=2`">政策法规</a> -->
                  </span>
                </div>
                <div class="homeNewsec02Wrap homeDjgzRightWrap">
                  <ul class="news_list news_list02">
                    <li v-for="(item,index) in newsRightZcfg" :key="index" >
                      <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                      <p class="news_list_inner single-line">
                        <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}/detail?id=${item.id}`">{{item.title}}</a>
                        <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 纪检举报 -->
        <div class="homeJjjb">
            <div class="homeJjjbBg"></div>
            <div class="homeJjjbBox homeJjjbBox clearfloat">
              <div class="fl homeJjjbWrap">
                <a>
                  <div class="homeJjjbHd">纪委联系方式 <span class="trans"> →</span></div>
                  <div class="homeJjjbLine"></div>
                  <div class="homeJjjbGrop">
                    <p>
                      举报电话：0931-8730486
                    </p>
                    <p>
                      举报地址：甘肃省兰州市城关区南昌路1716号公航旅大厦11楼1115办公室
                    </p>
                  </div>
                </a>
              </div>
              <div class="fr homeJjjbWrap">
                <a>
                  <div class="homeJjjbHd">信访联系方式 <span class="trans"> →</span></div>
                  <div class="homeJjjbLine"></div>
                  <div class="homeJjjbGrop">
                    <p>
                      举报电话：0931-8150588
                    </p>
                    <p>
                      举报邮箱：xnyjtxf@qq.com
                    </p>
                    <p>
                      举报地址：甘肃省兰州市城关区南昌路1716号公航旅大厦11楼
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

        <div class="homeYqlj bgfff mt10">
          <ul class="homeYqljList clearfloat">
            <li v-for="(item, index) in homeYqljList" :key="index">
              <a :href="item.url" target="_blank">
                <!-- <img :src="item.img" alt=""> -->
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.img"
                  fit="fill"></el-image>
              </a>
            </li>
          </ul>
        </div>


      </div>
      

    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  name: 'home',
  data(){
    return {
      newsRightDjgz: [],
      newsRightJtDt: [],
      newsRightJjjc: [],
      newsRightQtgz: [],
      newsRightZcfg: [],
      newsRightGhgz: [],
      newsRightNotice: [],
      newsRightDs: [],
      homeYqljList: [
        {
          title: '中国人民政府网站',
          img: require('../assets/img/temp/yqlj_img_zyrmzf.jpg'),
          id: '1',
          url: 'https://www.gov.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '国家发改委网站',
          img: require('../assets/img/temp/yqlj_img05.jpg'),
          id: '1',
          url: 'https://www.ndrc.gov.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '甘肃省发改委',
          img: require('../assets/img/temp/yqlj_img_gsfgw.jpg'),
          id: '1',
          url: 'http://fzgg.gansu.gov.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '国家能源局甘肃监管办公室',
          img: require('../assets/img/temp/yqlj_img_gjnygsbgs.jpg'),
          id: '1',
          url: 'http://gsb.nea.gov.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '甘肃省公航旅集团',
          img: require('../assets/img/temp/yqlj_img04.png'),
          id: '1',
          url: 'http://www.ghatg.com/',
          creat_time: '2021-05-14'
        },
        {
          title: '国网甘肃省电力公司',
          img: require('../assets/img/temp/yqlj_img_gwgssdlgs.jpg'),
          id: '1',
          url: 'http://www.gs.sgcc.com.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '华能甘肃能源开发有限公司',
          img: require('../assets/img/temp/yqlj_img_hngsnykfyxgs.jpg'),
          id: '1',
          url: 'https://www.chng.com.cn/detail_qygs/-/article/uYZwaH5JfpgQ/v/774399.html',
          creat_time: '2021-05-14'
        },
        {
          title: '大唐甘肃发电有限公司',
          img: require('../assets/img/temp/yqlj_img_dtfd.jpg'),
          id: '1',
          url: 'http://www.cdt-gsu.com/',
          creat_time: '2021-05-14'
        },
        {
          title: '国家电力投资集团公司甘肃分公司',
          img: require('../assets/img/temp/yqlj_img_gjdltz.jpg'),
          id: '1',
          url: 'http://zhaopin.spic.com.cn/hrjs/detailMsg?hrefAction=0001OW1000000009UOI5',
          creat_time: '2021-05-14'
        },
        {
          title: '中国电建集团甘肃分公司',
          img: require('../assets/img/temp/yqlj_img_zdjgs.jpg'),
          id: '1',
          url: 'http://www.gsnytz.com/',
          creat_time: '2021-05-14'
        },
      ],
      homeZtzlActive: 0,
      hdActive: 0,
      hdActive01: 0,
      hdList: [
        // {
        //   name: '集团动态',
        //   id: 1,
        // },
        {
          name: '公司要闻',
          id: 2,
          url: 'news'
        },
        {
          name: '子公司动态',
          id: 3,
          url: 'news?t=1'
        },
        {
          name: '项目资讯',
          id: 4,
          url: 'news?t=2'
        },
      ],
      hdList01: [
        {
          name: '党建工作',
          id: 1,
          url: 'party'
        },
        {
          name: '纪检监察',
          id: 2,
          url: 'party?t=1'
        },
        {
          name: '群团工作',
          id: 3,
          url: 'party?t=2'
        },
        {
          name: '工会工作',
          id: 4,
          url: 'party?t=3'
        },
      ],
      newsTop: [],
      newsLeft: [
        // {
        //   title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研',
        //   img: require('../assets/img/home_vedio_img02.jpg'),
        //   id: '2'
        // }
      ],
      
      homeZtzlList: [
        {
          title: '中国共产党第二十届三中全会',
          img: require('../assets/img/temp/zt_esjszqh_a.jpg'),
          id: '1',
          url: 'https://www.12371.cn/special/20jszqh/',
          creat_time: '2021-05-14'
        },
        {
          title: '全面从严治党重要论述数据库',
          img: require('../assets/img/temp/zt_xjpzsj_a.jpg'),
          id: '1',
          url: 'http://people.ccdi.gov.cn/',
          creat_time: '2021-05-14'
        },
        {
          title: '在线廉政教育基地',
          img: require('../assets/img/temp/zt_zxlz_a.jpg'),
          id: '1',
          url: 'http://www.gsjw.gov.cn/category/zxlzjyjd',
          creat_time: '2021-05-14'
        },
        {
          title: '廉洁文化建设',
          img: require('../assets/img/temp/zt_ljwhjs_a.jpg'),
          id: '1',
          url: '/specail?t=2',
          creat_time: '2021-05-14'
        },
        // {
        //   title: '喜迎二十大',
        //   img: require('../assets/img/temp/ztzl_img01.jpg'),
        //   id: '1',
        //   url: 'https://www.12371.cn/special/xy20d/',
        //   creat_time: '2021-05-14'
        // },
        {
          title: '学习党史',
          img: require('../assets/img/temp/ztzl_img05.png'),
          id: '1',
          url: 'http://www.12371.cn/dsxx/',
          creat_time: '2021-05-14'
        },
        // {
        //   title: '奋斗百年路启航新征程',
        //   img: require('../assets/img/temp/ztzl_img04.png'),
        //   id: '1',
        //   url: 'http://www.12371.cn/special/jd100zn/',
        //   creat_time: '2021-05-14'
        // },
        {
          title: '贯彻落实党中央八项规定',
          img: require('../assets/img/temp/ztzl_img08.jpg'),
          id: '1',
          url: 'http://fanfu.people.com.cn/GB/143349/353985/',
          creat_time: '2021-05-14'
        }
      ],
      homeProjectList: [
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_01.jpg'),
          id: '1',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_02.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_03.jpg'),
          id: '1',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_04.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_05.jpg'),
          id: '1',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_06.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_07.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_08.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_09.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
        {
          title: '光伏发电',
          img: require('../assets/img/temp/business_10.jpg'),
          id: '2',
          creat_time: '2021-05-14'
        },
      ],
      newsRight: [
        {
          title: '集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
        {
          title: '集团公司领导赴焉支山景区调研指导工作',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
          cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
          creat_time: '2021-05-14'
        },
      ],
      newsRight01: [],
      newsRight02: [],
      newsRight03: [],
      options01: [
        {
          name: '外交部',
          url: 'http://www.fmprc.gov.cn'
        },
        {
          name: '国防部',
          url: 'http://www.mod.gov.cn/'
        },
        {
          name: '发展改革委',
          url: 'http://www.ndrc.gov.cn/'
        },
        {
          name: '教育部',
          url: 'http://www.moe.edu.cn/'
        },
        {
          name: '科技部',
          url: 'http://www.most.gov.cn/'
        },
        {
          name: '工业和信息化部',
          url: 'http://www.miit.gov.cn/'
        },
        {
          name: '自然资源部',
          url: 'http://www.mnr.gov.cn/'
        },
        {
          name: '公安部',
          url: 'http://www.mps.gov.cn/'
        },
        {
          name: '民政部',
          url: 'http://www.mca.gov.cn'
        },
        {
          name: '司法部',
          url: 'http://www.moj.gov.cn'
        },
        {
          name: '财政部',
          url: 'http://www.mof.gov.cn'
        },
        {
          name: '人力资源社会保障部',
          url: 'http://www.mohrss.gov.cn'
        },
        {
          name: '生态环境部',
          url: 'http://www.mee.gov.cn'
        },
        {
          name: '住房城乡建设部',
          url: 'http://www.mohurd.gov.cn'
        },
        {
          name: '交通运输部',
          url: 'http://www.mot.gov.cn'
        },
        {
          name: '水利部',
          url: 'http://www.mwr.gov.cn/'
        },
        {
          name: '农业农村部',
          url: 'http://www.moa.gov.cn/'
        },
        {
          name: '商务部',
          url: 'http://www.mofcom.gov.cn'
        },
        {
          name: '文化旅游部',
          url: 'http://www.mct.gov.cn/'
        },
        {
          name: '国家卫生健康委员会',
          url: 'http://www.nhc.gov.cn'
        },
        {
          name: '应急管理部',
          url: 'http://www.chinasafety.gov.cn/'
        },
        {
          name: '人民银行',
          url: 'http://www.pbc.gov.cn'
        },
        {
          name: '审计署',
          url: 'http://www.audit.gov.cn/'
        },
        {
          name: '国资委',
          url: 'http://www.sasac.gov.cn/index.html'
        },
        {
          name: '海关总署',
          url: 'http://www.customs.gov.cn/publish/portal0/'
        },
        {
          name: '税务总局',
          url: 'http://www.chinatax.gov.cn/'
        },
        {
          name: '国家市场监督管理总局',
          url: 'http://www.aqsiq.gov.cn/'
        },
        {
          name: '国家广播电视总局',
          url: 'http://www.sapprft.gov.cn/'
        },
        {
          name: '体育总局',
          url: 'http://www.sport.gov.cn/'
        },
        {
          name: '统计局',
          url: 'http://www.stats.gov.cn/'
        },
        {
          name: '知识产权局',
          url: 'http://www.sipo.gov.cn/'
        },
        {
          name: '宗教局',
          url: 'http://www.sara.gov.cn/'
        },
        {
          name: '参事室',
          url: 'http://www.counsellor.gov.cn/'
        },
        {
          name: '国管局',
          url: 'http://www.ggj.gov.cn/'
        },
        {
          name: '版权局',
          url: 'http://www.ncac.gov.cn/'
        },
        {
          name: '法制办',
          url: 'http://www.chinalaw.gov.cn/'
        },
        {
          name: '国务院研究室',
          url: 'http://www.gov.cn/gjjg/2005-12/26/content_137261.htm'
        },
        {
          name: '台办',
          url: 'http://www.gwytb.gov.cn/'
        },
        {
          name: '新闻办',
          url: 'http://www.scio.gov.cn/'
        },
        {
          name: '新华社',
          url: 'http://www3.xinhuanet.com/'
        },
        {
          name: '中科院',
          url: 'http://www.cas.cn/'
        },
        {
          name: '社科院',
          url: 'http://cass.cssn.cn/'
        },
        {
          name: '工程院',
          url: 'http://www.cae.cn/'
        },
        {
          name: '发展研究中心',
          url: 'http://www.drc.gov.cn/'
        },
        {
          name: '中央党校',
          url: 'http://www.nsa.gov.cn/'
        },
        {
          name: '气象局',
          url: 'http://www.cma.gov.cn/'
        },
        {
          name: '地震局',
          url: 'http://www.cea.gov.cn/'
        },
        {
          name: '证监会',
          url: 'http://www.csrc.gov.cn/pub/newsite/'
        }
      ],
      options02: [
        {
          name: '省发展改革委',
          url: 'http://fzgg.gansu.gov.cn/'
        },
        {
          name: '省教育厅',
          url: 'http://jyt.gansu.gov.cn/'
        },
        {
          name: '省科技厅',
          url: 'http://kjt.gansu.gov.cn/'
        },
        {
          name: '省工信厅',
          url: 'http://gxt.gansu.gov.cn/'
        },
        {
          name: '省民族事务委员会',
          url: 'http://mzsw.gansu.gov.cn/'
        },
        {
          name: '省公安厅',
          url: 'http://gat.gansu.gov.cn/'
        },
        {
          name: '省民政厅',
          url: 'http://mzt.gansu.gov.cn/'
        },
        {
          name: '省司法厅',
          url: 'http://sft.gansu.gov.cn/'
        },
        {
          name: '省财政厅',
          url: 'http://czt.gansu.gov.cn/'
        },
        {
          name: '省人社厅',
          url: 'http://rst.gansu.gov.cn/'
        },
        {
          name: '省自然资源厅',
          url: 'http://zrzy.gansu.gov.cn/'
        },
        {
          name: '省生态环境厅',
          url: 'http://sthj.gansu.gov.cn/'
        },
        {
          name: '省住建厅',
          url: 'http://zjt.gansu.gov.cn/'
        },
        {
          name: '省交通运输厅',
          url: 'http://jtys.gansu.gov.cn/'
        },
        {
          name: '省水利厅',
          url: 'http://slt.gansu.gov.cn/'
        },
        {
          name: '省农业农村厅',
          url: 'http://nync.gansu.gov.cn/'
        },
        {
          name: '省商务厅',
          url: 'http://swt.gansu.gov.cn/'
        },
        {
          name: '省文旅厅',
          url: 'http://wlt.gansu.gov.cn/'
        },
        {
          name: '省卫生健康委',
          url: 'http://wsjk.gansu.gov.cn/'
        },
        {
          name: '省退役军人厅',
          url: 'http://tyjr.gansu.gov.cn/'
        },
        {
          name: '省应急厅',
          url: 'http://yjgl.gansu.gov.cn/'
        },
        {
          name: '省审计厅',
          url: 'http://sjt.gansu.gov.cn/'
        },
        {
          name: '省政府外事办',
          url: 'http://wsb.gansu.gov.cn/'
        }
      ],
      options03: [
        {
          name: '金川集团有限公司',
          url: 'http://www.jnmc.com/'
        },
        {
          name: '酒泉钢铁集团',
          url: 'http://www.jiugang.com/'
        },
        {
          name: '白银有色集团股份有限公司',
          url: 'http://www.bynmc.com/'
        },
        {
          name: '甘肃建投',
          url: 'http://www.gsjtw.cc/'
        },
        {
          name: '甘肃省公路航空旅游投资集团有限公司',
          url: 'http://www.ghatg.com/'
        }
      ],
      options04: [
        {
          name: '浙江省交通投资集团有限公司',
          url: 'http://www.cncico.com/'
        },
        {
          name: '江苏交通控股有限公司',
          url: 'http://www.jchc.cn'
        },
        {
          name: '广西交通投资集团',
          url: 'http://www.gx.xinhuanet.com/wq/gxjt/'
        },
        {
          name: '广东省交通投资集团',
          url: 'http://www.gdcg.com.cn'
        },
        {
          name: '山东高速集团有限公司',
          url: 'http://www.sdhsg.com'
        },
        {
          name: '河北建设投资集团',
          url: 'http://www.hecic.com.cn'
        },
        {
          name: '河南高速公路发展有限',
          url: 'http://www.hnew.com.cn'
        },
        {
          name: '江西省投资集团公司',
          url: 'http://www.jxic.com/'
        }
      ],
      options05: [
        {
          name: '国家开发银行',
          url: 'http://www.cdb.com.cn'
        },
        {
          name: '中国进出口银行',
          url: 'http://www.eximbank.gov.cn'
        },
        {
          name: '中国农业发展银行',
          url: 'http://www.adbc.com.cn'
        },
        {
          name: '中国工商银行',
          url: 'http://www.icbc.com.cn'
        },
        {
          name: '中国农业银行',
          url: 'http://www.abchina.com'
        },
        {
          name: '中国银行',
          url: 'http://www.bank-of-china.com'
        },
        {
          name: '中国建设银行',
          url: 'http://www.ccb.com/cn/home/indexv3.html'
        },
        {
          name: '交通银行',
          url: 'http://www.bankcomm.com'
        },
        {
          name: '招商银行',
          url: 'http://www.cmbchina.com'
        },
        {
          name: '信托网',
          url: 'http://www.trust-one.com'
        }
      ],
      value01: '国家部委网站',
      value02: '省直部门网站',
      value03: '省属国企网站',
      value04: '国内同行网站',
      value05: '金融机构网站',
      HomeNewsec03List: [
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/cd_img01.png'),
        //   id: '1',
        //   creat_time: '2021-05-14'
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/cd_img02.png'),
        //   id: '1',
        //   creat_time: '2021-05-14'
        // },
      ],
      businessActive: -1,
      businessActive01: false,
      newsListPersonal: [],
      newsListMedial: []
    }
  },
  mounted(){
    this.bannerInit()
    // this.bannerHomeSec01()
    // this.bannerHomeNewsec03()

    this.getImprotNews()
    this.getImgNews()
    // this.companyNewsJtdt()
    this.companyNews()
    this.companyNewsSub()
    this.companyNewsParty()
    this.companyNewsNotice()
    this.companyNewsDs()
    // this.companyNewsPersonnel()
    // this.companyNewsMedail()
    this.specailList()
    this.bannerProject()
    this.companyNewsDjgz()
    this.companyNewsJjjc()
    this.companyNewsQtgz()
    this.companyNewsGhgz()
    this.companyNewsZcfg()
  },
  methods: {
    mouseenterZtzl(index) {
      this.homeZtzlActive = index
    },
    // 新闻头条
    async getImprotNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImportNews', {company_id: _this.companyId});
      _this.newsTop = data.data.list;
    },
    // 焦点图
    async getImgNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImgNews', {company_id: _this.companyId});
      _this.newsLeft = data.data.list.slice(0,6);
      this.$nextTick(() => {
        _this.bannerHomeSec01()
      })
    },
    // 集团动态
    async companyNewsJtdt() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "7", page: 1, pageSize: 10});
      _this.newsRightJtDt = data.data.list.slice(0,8);
    },
    // 公司新闻
    async companyNews() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "346", page: 1, pageSize: 10});
      _this.newsRight01 = data.data.list.slice(0,8);
    },
    // 子分公司动态
    async companyNewsSub() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "347", page: 1, pageSize: 10});
      _this.newsRight02 = data.data.list.slice(0,8);
    },
    // 项目动态
    async companyNewsParty() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "348", page: 1, pageSize: 10});
      _this.newsRight03 = data.data.list.slice(0,8);
    },
    // 公示公告
    async companyNewsNotice() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "349", page: 1, pageSize: 10});
      _this.newsRightNotice = data.data.list.slice(0,6);
    },
    // 党史学习
    async companyNewsDs() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "527", page: 1, pageSize: 10});
      _this.newsRightDs = data.data.list.slice(0,6);
    },
    // 员工风采
    async companyNewsPersonnel() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "246", page: 1, pageSize: 6});
      _this.newsListPersonal = data.data.list;
    },
    // 媒体聚焦
    async companyNewsMedail() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "247", page: 1, pageSize: 6});
      _this.newsListMedial = data.data.list;
    },
    // 专题广告
    async specailList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: "425", page: 1, pageSize: 6});
      _this.HomeNewsec03List = data.data.list.slice(0,6);
      this.$nextTick(() => {
        _this.bannerHomeNewsec03()
      })
    },
    businessShow(index, item) {
      console.log('index :>> ', index);
      console.log('item :>> ', item);
      var _this = this
      _this.businessActive = index
      _this.businessActive01 = true
    },
    businessClose(){
      var _this = this
      _this.businessActive = -1
      _this.businessActive01 = false
    },
    hdShow(index) {
      var _this = this

      _this.hdActive = index
    },
    hdShow01(index) {
      var _this = this

      _this.hdActive01 = index
    },
    // banner
    bannerInit(){
      new Swiper(".bannerHome", {
        // direction: 'vertical',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-banner",
          clickable :true,
        },
        navigation: {
          nextEl: '.swiper-button-next-banner',
          prevEl: '.swiper-button-prev-banner',
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图
    bannerHomeSec01(){
      new Swiper(".bannerHomeSec01", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-sec01",
          clickable :true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图-cd
    bannerHomeNewsec03(){
      new Swiper(".bannerHomeNewsec03", {
        effect : 'fade',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-nessec03",
          clickable :true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 项目风采
    bannerProject(){
      var swiper = new Swiper(".homeProjectSwiper", {
        speed: 6000,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        slidesPerView : 4.5,
        spaceBetween : 20,
        // slidesPerGroup: 4,
        loop: true,
        loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
      var comtainer = document.getElementById('homeProjectSwiper');
      comtainer.onmouseenter = function () {
        swiper.autoplay.stop();
      };
      comtainer.onmouseleave = function () {
        swiper.autoplay.start();
      }
    },
    // 党建工作
    async companyNewsDjgz() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "355", page: 1, pageSize: 10});
      _this.newsRightDjgz = data.data.list.slice(0,6);
    },
    // 纪检监察
    async companyNewsJjjc() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "356", page: 1, pageSize: 10});
      _this.newsRightJjjc = data.data.list.slice(0,6);
    },
    // 群团工作
    async companyNewsQtgz() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "357", page: 1, pageSize: 10});
      _this.newsRightQtgz = data.data.list.slice(0,6);
    },
    // 工会工作
    async companyNewsGhgz() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "358", page: 1, pageSize: 10});
      _this.newsRightGhgz = data.data.list.slice(0,6);
    },
    // 政策法规
    async companyNewsZcfg() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "365", page: 1, pageSize: 10});
      _this.newsRightZcfg = data.data.list.slice(0,6);
    },
    // 专题专栏
    // bannerSpecail(){
    //   var swiper = new Swiper(".bannerSpecail", {
    //     autoplay: {
    //       disableOnInteraction: false,
    //     },
    //     loop: true,
    //     slidesPerView : 5,
    //     spaceBetween : 10,
    //     navigation: {
    //       nextEl: '.swiper-button-next-specail',
    //       prevEl: '.swiper-button-prev-specail',
    //       hideOnClick: true,
    //     },
    //     observer: true, //修改swiper自己或子元素时，自动初始化swiper
    //     observeParents: true, //修改swiper的父元素时，自动初始化swiper
    //     autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
    //   })
    //   var comtainer = document.getElementById('bannerSpecail');
    //   comtainer.onmouseenter = function () {
    //     swiper.autoplay.stop();
    //   };
    //   comtainer.onmouseleave = function () {
    //     swiper.autoplay.start();
    //   }
    // }
  }
}
</script>
<style>
.bannerHome {
  height: 612px;
  position: relative;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
}
.bannerHome img {
  width: 100%;
}
.swiper-pagination-banner {
  width: 100%;
  color: #fff;
  font-size: 16px;
  right: auto !important;
  top: auto !important;
  margin: 0 !important;
  transform: none !important;
  left: 0;
  bottom: 10px;
}
.swiper-pagination-current {
  font-size: 20px;
}
.bannerHome.swiper-container-vertical .swiper-pagination-bullet {
  display: inline-block !important;
  margin: 0 5px;
}
.secBox01 {
  padding: 60px 0;
  background-image: url(../assets/img/home_bg_sec01.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.secBox01Hd {
  margin-bottom: 30px;
  line-height: 48px;
}
.secBox01Title {
  font-size: 40px;
  color: #8A8A8A;
  text-transform: Uppercase;
}
.secBox01Txt {
  font-size: 30px;
  color: #535353;
}
.secBox01Tab {
  margin-bottom: 54px;
  text-align: center;
}
.secBox01Tab span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  margin: 0 26px;
  font-size: 16px;
  color: #282828;
  background-color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.secBox01Tab span.active {
  color: #fff;
  background-color: #BC8F68;
}
.bannerHomeSec01 {
  position: relative;
}
.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.bannerHomeSec01 img:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}
.bannerHomeSec01 p {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 118px 0 12px;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  text-align: left;
}
.bannerHomeSec01 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 0;
  background-color:rgba(255, 255, 255, 0.8);
  opacity: 1;
}
.bannerHomeSec01 .swiper-pagination-bullet-active {
  background-color:#CE251F;
}
.bannerHomeSec01 .swiper-pagination {
  text-align: right;
  left: auto;
  right: 8px;
}
.homeSecHd {
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #4A4643;
  margin-bottom: 30px;
}
.homeSecHdLeft {
  text-align: left;
}
.homeSecHd span {
  display: inline-block;
  vertical-align: middle;
}
.homeSecHd span.homeSecHdDian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #4A4643;
  margin: 0 10px;
}
.homeSecHd span.homeSecHTitle {
  font-size: 30px;
  font-weight: bold;
}
.bannerHome .swiper-button-next,
.bannerHome .swiper-button-prev {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: rgba(255,255,255,0.4);
  margin-top: -30px;
}
.homeCd {
  margin-top: 30px;
}
.homeCdItem a {
  display: block;
  height: 146px;
}
.homeCdItem img {
  width: 100%;
  height: 100%;
}
</style>